import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { PATH } from "./common/common";
import Layout from "./components/Layout";
import DetailPayment from "./pages/DetailPayment";
import HomePage from "./pages/HomePage";
import OrderDetail from "./pages/OrderDetails";
import Payment from "./pages/Payment";
import QrCode from "./pages/QrCode";
import PaymentHistory from "./pages/PaymentHistory";

const MyRoutes = createBrowserRouter([
  {
    // parent route component
    element: <Layout />,
    // child route components
    children: [
      {
        path: PATH.HOME,
        element: <HomePage />,
      },
      {
        path: PATH.PAYMENT,
        element: <Payment />,
      },
      {
        path: PATH.DETAIL_PAYMENT,
        element: <DetailPayment />,
      },
      {
        path: PATH.ORDER,
        element: <OrderDetail />,
      },
      {
        path: PATH.QR_CODE,
        element: <QrCode />,
      },
      {
        path: PATH.PAYMENT_HISTORY,
        element: <PaymentHistory />,
      },
    ],
  },
]);

export default MyRoutes;
