import React from "react";
import styles from "../styles/home.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH } from "../common/common";
import { Button } from "antd";

export default function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(PATH.PAYMENT);
  };
  const handleDirectHistory = () => {
    navigate(PATH.PAYMENT_HISTORY);
  };
  return (
    <div>
      <section className={styles["banner-section-first"]}>
        <img
          src="/assets/images/banner1.png"
          className="responsive-image"
          alt="Payment Gateway Introduction"
        />
      </section>

      <section className={`${styles["banner-section-second"]} mb-4`}>
        <img
          src="/assets/images/banner2.png"
          className="responsive-image"
          alt="Payment Gateway Introduction"
        />
      </section>
      <div className="p-3 d-flex-between" style={{gap:"1rem"}}>
        <Button onClick={handleClick} type="primary" className="w-100 d-block">
          {t("create_link_now")}
        </Button>
        <Button onClick={handleDirectHistory} color="default" className="w-100 d-block">
          {t("Payment_history")}
        </Button>
      </div>
    </div>
  );
}
