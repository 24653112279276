import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PATH } from "../common/common";
import styles from "../styles/detaiPayment.module.css";

const PaymentMethod = ({ isOpenPath=true,detailPayment, typeTransaction, setTypeTransaction, handleCalSendAmount }) => {
    const { t } = useTranslation();
    let { paymentCode } = useParams();
   const paymentMethodIcon = {
        zalopayPaymentUrl: {
          img: "/assets/icons/zalo.svg",
          label: t("zalo_pay"),
          limit: "20,000 - 1,000,000 VND",
          fee: "3%",
          feeAmount: 3,
          limitAmount: { min: 20000, max: 1000000 }
        },
        bankPaymentUrl: {
          img: "/assets/icons/bank_transfer.svg",
          label: t("bank_transfer"),
          limit: "50,000 - 50,000,000 VND",
          fee: "3%",
          feeAmount: 3,
          limitAmount: { min: 50000, max: 50000000 }
        },
        
        // cardPaymentUrl: {
        //   img: "/assets/icons/card.svg",
        //   label: i18n.t("card"),
        //   limit: "20,000 - 1,000,000 VND",
        //   fee: "30%",
        //   feeAmount: 30,
        //   limitAmount: { min: 20000, max: 1000000 }
        // },
        momoPaymentUrl: {
          img: "/assets/icons/momo.svg",
          label: t("momo"),
          limit: "20,000 - 5,000,000 VND",
          fee: "3%",
          feeAmount: 3,
          limitAmount: { min: 20000, max: 5000000 }
        },
        viettelpayPaymentUrl: {
          img: "/assets/icons/viettel.svg",
          label: t("viettel_pay"),
          limit: "20,000 - 10,000,000 VND",
          fee: "3%",
          feeAmount: 3,
          limitAmount: { min: 20000, max: 10000000 }
        },
        usdtPaymentUrl: {
          img: "/assets/icons/usdt.svg",
          label: t("usdt_payment"),
          limit: "20,000 - 5,000,000 USDT",
          fee: "3%",
          feeAmount: 3,
          limitAmount: { min: 20000, max: 5000000 }
        },
      };
    const navigate = useNavigate()

    const handleOpenNewTab = (key, data) => {
        if(isOpenPath)
        switch (key) {
            case "bankPaymentUrl":
            case "cardPaymentUrl":
                window.open(data);
                break
            case "zalopayPaymentUrl":
            case "momoPaymentUrl":
            case "viettelpayPaymentUrl":
            case "usdtPaymentUrl":
                window.open(`${window.location.origin}${PATH.QR_CODE.replace(":paymentCode",paymentCode).replace(":typeTransaction",key)}`,"_blank")
                // navigate(PATH.QR_CODE.replace(":paymentCode",paymentCode).replace(":typeTransaction",key),)
                break;
            default:
                break;
        }

    }

    return (
        <div className={styles["detail-payment"]}>
            <div className="title">{t("payment_method")}</div>
            {detailPayment && Object.keys(paymentMethodIcon)?.map((data, index) => {
                const isWithinLimit = detailPayment.paymentAmount >= paymentMethodIcon[data].limitAmount.min &&
                    detailPayment.paymentAmount <= paymentMethodIcon[data].limitAmount.max;

                return isWithinLimit && detailPayment[data] &&(
                    <div className="d-flex" key={index}>
                        {/* <Radio
                            value={data}
                            checked={typeTransaction === data}
                            onChange={() => {
                                setTypeTransaction(data);
                                handleCalSendAmount(paymentMethodIcon[data].feeAmount);
                            }}
                        /> */}
                        <div onClick={() => handleOpenNewTab(data, detailPayment[data])} className={`${styles["payment-method"]} ${data === typeTransaction ? "active-common" : ""}`}>
                            <div>
                                <div className="title-content">{paymentMethodIcon[data].label}</div>
                                <div className="content">
                                    <div>{paymentMethodIcon[data].limit}</div>
                                    <div>{t("transaction_fee")} {paymentMethodIcon[data].fee}</div>
                                </div>
                            </div>
                            <img className={styles["payment-icon"]} src={paymentMethodIcon[data].img} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default PaymentMethod;
