import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import styles from "../styles/header.module.css";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { useLocation } from "react-router-dom";
import { PATH } from "../common/common";

const MyHeader = () => {
  const { i18n } = useTranslation();
  const { pathname, search } = useLocation();
  const [searchParams] = useState(new URLSearchParams(search));

  const handleChange = (value) => {
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    const lang = searchParams.get("lang");
    const listLang = ["vi", "en", "zh"];
    if (lang && listLang.includes(lang)) {
      i18n.changeLanguage(lang);
    }
  }, [i18n, searchParams]);

  return (
    <div className={styles.header}>
      <div className={`${styles.content} d-flex-between`}>
        <div className="d-flex align-center">
          {pathname !== PATH.HOME && (
            <Button
              className="me-2"
              onClick={() => window.history.back()}
              size="small"
              type="link"
              shape="circle"
              icon={<LeftOutlined />}
            ></Button>
          )}
          <div className="logo d-block">
            <img
              src="/Logo.png"
              alt="AnonyPay | Secure, Fast, Anony payment gateway"
            />
          </div>
        </div>

        <div>
          <Select
            defaultValue={i18n.language}
            style={{ width: 130 }}
            value={i18n.language}
            onChange={handleChange}
            options={[
              {
                value: "vi",
                label: (
                  <span className="d-flex align-center">
                    <img
                      src="/assets/icons/flag_VI.svg"
                      className="pe-1"
                      width="20"
                      alt=""
                    />
                    <span>Tiếng Việt</span>
                  </span>
                ),
              },
              {
                value: "en",
                label: (
                  <span className="d-flex align-center">
                    <img
                      src="/assets/icons/flag_EN.svg"
                      className="pe-1"
                      width="20"
                      alt=""
                    />
                    <span>English</span>
                  </span>
                ),
              },
              {
                value: "zh",
                label: (
                  <span className="d-flex align-center ">
                    <img
                      src="/assets/icons/flag_CN.svg"
                      className="pe-1"
                      width="20"
                      alt=""
                    />
                    <span>中国人</span>
                  </span>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default MyHeader;
