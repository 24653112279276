import { Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { PATH, replaceNumberToAmount } from "../common/common";
import { useNavigate } from "react-router-dom";

export default function PaymentHistory(params) {
  const navigate = useNavigate();
  const [data] = useState(
    window.localStorage.getItem("AnonyPayment_Web_paymentHistory")
      ? JSON.parse(
          window.localStorage.getItem("AnonyPayment_Web_paymentHistory")
        )
      : []
  );

  const columns = [
    {
      title: "Thời gian",
      with: "50%",
      render: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Số tiền",
      render: (row) => replaceNumberToAmount(row.paymentAmount),
    },
    {
      title: "Mã giao dịch",
      render: (row) => row.paymentCode,
    },
    {
      title: "Hành động",
      render: (row) => (
        <div className="d-flex ">
          <img
            src="/assets/icons/eye.svg"
            height={20}
            alt=""
            className="me-3"
            onClick={() => {
              navigate(PATH.ORDER.replace(":paymentCode", row.paymentCode));
            }}
          />
          <img
            src="/assets/icons/copy.svg"
            height={20}
            alt=""
            onClick={() => {
              const { createdAt, paymentCode, ...newURL } = row;
              navigate(PATH.PAYMENT+"?"+new URLSearchParams(newURL).toString());
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
}
