import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import MyInput from "../components/Input";
import MySelect from "../components/Select";
import { Alert, Button, Form, Space } from "antd";
import { AuditOutlined } from "@ant-design/icons";
import styles from "../styles/payment.module.css";
import MyInputNumber from "../components/InputNumber";
import { PATH, replaceNumberToAmount } from "../common/common";
import MyTextInput from "../components/TextInput";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { createPayOutRequest, getBanks } from "../service/ApiService";

const MAX_AMOUNT = 100000000;
const MIN_AMOUNT = 200000;
const SUGGEST_AMOUNT = [1000000, 2000000, 3000000, 4000000, 5000000];
const SUGGEST_AMOUT_LENGTH = 5;
const MAX_LENGTH_CONTENT = 200;

function suggestAmountForUser(inputNumber) {
  if (inputNumber > MAX_AMOUNT) return [MAX_AMOUNT];
  if (inputNumber < MIN_AMOUNT) return SUGGEST_AMOUNT;
  const time = SUGGEST_AMOUT_LENGTH;
  const arr = [];
  for (let i = 0; i < time; i++) {
    inputNumber < MAX_AMOUNT &&
      inputNumber > 0 &&
      arr.push((inputNumber *= 10));
  }
  return arr.filter((v) => v <= MAX_AMOUNT);
}
const phoneRegExp =
  /^(\+?\d{1,4}[\s-]?)?(\(?\d{1,3}\)?[\s-]?)?\d{1,4}([\s-]?\d{1,4}){1,3}$/;

export default function Payment() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const initialValuesLocal = window.localStorage.getItem(
    "AnonyPayment_Web_submitPaymentData"
  )
    ? JSON.parse(
        window.localStorage.getItem("AnonyPayment_Web_submitPaymentData")
      )
    : {};

  const initialValues = {
    bankId:
      searchParams?.get("paymentOriginSourceId") ||
      initialValuesLocal?.bankId ||
      null,
    accountNumber:
      +searchParams?.get("paymentOriginName") ||
      initialValuesLocal?.accountNumber ||
      null,
    accountName:
      searchParams?.get("paymentOwner") ||
      initialValuesLocal?.accountName ||
      null,
    amount: searchParams?.get("paymentAmount") || initialValuesLocal?.amount || 0,
    content:
      searchParams?.get("paymentNote") || initialValuesLocal?.content || "",
    phoneNumber:
      searchParams?.get("phoneNumber") || initialValuesLocal?.phoneNumber || "",
    paymentRef:
      searchParams?.get("paymentRef") || initialValuesLocal?.paymentRef || "",
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(phoneRegExp, t("phone_invalid"))
      .required(t("phone_required")),
    bankId: Yup.number().required(t("bank_id_required")),
    accountNumber: Yup.string()
      .matches(/^\d{6,19}$/, t("account_number_validation"))
      .required(t("account_number_required")),
    accountName: Yup.string()
      .required(t("account_name_required"))
      .min(2, t("account_name_min_length"))
      .max(50, t("account_name_max_length")),
    amount: Yup.number(t("must_number"))
      .required(t("amount_required"))
      .min(
        MIN_AMOUNT,
        t("amount_min", { param: replaceNumberToAmount(MIN_AMOUNT) })
      )
      .max(
        MAX_AMOUNT,
        t("amount_max", { param: replaceNumberToAmount(MAX_AMOUNT) })
      ),
    content: Yup.string()
      .required(t("content_required"))
      .max(MAX_LENGTH_CONTENT),
  });

  const [apiKey, setApiKey] = useState(
    new URLSearchParams(search).get("apikey") ||
      window.localStorage.getItem("AnonyPayment_Web_submitPaymentApiKey") ||
      undefined
  );

  useEffect(() => {
    if (apiKey === "null") {
      window.localStorage.removeItem("AnonyPayment_Web_submitPaymentApiKey");
      setApiKey(undefined);
    } else if (apiKey) {
      window.localStorage.setItem(
        "AnonyPayment_Web_submitPaymentApiKey",
        apiKey
      );
    }
  }, [apiKey, search]);

  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [suggestAmount, setSuggestAmount] = useState(SUGGEST_AMOUNT);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const body = {
          paymentOwner: values.accountName,
          paymentOriginSource: banks?.find((v) => v?.value === values.bankId)
            .code,
          paymentOriginName: values.accountNumber,
          paymentOriginSourceId: values.bankId,
          paymentAmount: values.amount,
          paymentNote: values.content,
          phoneNumber: values.phoneNumber,
          paymentRef: values.paymentRef || undefined,
          apiKey: apiKey || undefined,
        };
        const rs = await createPayOutRequest(body);
        const paymentCode = rs?.paymentCode;
        setLoading(false);
        handleLocalPaymentHistory(rs, body);
        navigate(PATH.DETAIL_PAYMENT.replace(":paymentCode", paymentCode));
      } catch (error) {
        setLoading(false);
      }
    },
  });

  function handleLocalPaymentHistory(data, dataPayment) {
    if (!data?.paymentCode) {
      return;
    }
    const dataToSave = {
      paymentCode: data?.paymentCode,
      createdAt: data?.createdAt,
      ...dataPayment,
    };
    let dataLocal = window.localStorage.getItem(
      "AnonyPayment_Web_paymentHistory"
    )
      ? JSON.parse(
          window.localStorage.getItem("AnonyPayment_Web_paymentHistory")
        )
      : [];
    if (!Array.isArray(dataLocal)) {
      dataLocal = [];
    }

    dataLocal.unshift(dataToSave);
    if (dataLocal.length > 50) {
      dataLocal.pop();
    }
    window.localStorage.setItem(
      "AnonyPayment_Web_paymentHistory",
      JSON.stringify(dataLocal)
    );
  }

  const handleGetBank = async () => {
    const banks = await getBanks();
    setBanks(
      banks.map((v) => ({
        value: v.id,
        name: v.shortName,
        logo: v.logo,
        code: v.code,
      }))
    );
  };

  const handleSetSuggestAmount = (value) => {
    setSuggestAmount(value);
  };
  const { values, setFieldValue, errors, touched, handleBlur, submitForm } =
    formik;

  useEffect(() => {
    handleGetBank();
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      "AnonyPayment_Web_submitPaymentData",
      JSON.stringify(values)
    );
  }, [values]);

  const filterOption = (input, option) =>
    (option?.name ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <div className={`${styles.payment} box`}>
        <div className="title mb-2"> {t("payment")}</div>
        <Form layout="vertical">
          <Form.Item label={t("infor_receiving_money")} title>
            <Alert message={t("warning_create_payment")} type="warning" />
          </Form.Item>
          <MyInput
            size="large"
            value={values.phoneNumber}
            name="phoneNumber"
            label={t("phone")}
            required
            placeholder={t("phone")}
            onBlur={handleBlur}
            touched={touched.phoneNumber}
            error={errors.phoneNumber}
            onChange={(e) => {
              setFieldValue("phoneNumber", e.target.value);
            }}
          />
          <MySelect
            defaultValue={values.bankId}
            onBlur={handleBlur}
            touched={touched.bankId}
            error={errors.bankId}
            options={banks}
            showSearch={true}
            optionLabelProp="name"
            filterOption={filterOption}
            required
            label={t("select_bank")}
            optionRender={(option) => (
              <Space>
                <div className="d-flex-center">
                  <img
                    className={styles["bank-logo"]}
                    src={option?.data?.logo}
                  ></img>
                  <span> {option?.data?.name}</span>
                </div>
              </Space>
            )}
            onChange={(e) => {
              setFieldValue("bankId", e);
            }}
            size="large"
            name="bankId"
            placeholder={t("select_bank")}
          />
          <MyInput
            size="large"
            value={values.accountNumber}
            name="accountNumber"
            label={t("account_number")}
            required
            placeholder={t("account_number")}
            onBlur={handleBlur}
            touched={touched.accountNumber}
            error={errors.accountNumber}
            onChange={(e) => {
              setFieldValue("accountNumber", e.target.value);
            }}
          />
          <MyInput
            size="large"
            value={values.accountName}
            name="accountName"
            label={t("recipient_name")}
            required
            placeholder={t("recipient_name")}
            onBlur={handleBlur}
            touched={touched.accountName}
            error={errors.accountName}
            onChange={(e) => {
              setFieldValue("accountName", e.target.value);
            }}
          />
          <MyInputNumber
            size="large"
            name="amount"
            classNameForm={styles["custom-input-number"]}
            required
            label={t("requested_amount")}
            placeholder={t("VND_amount")}
            value={values.amount}
            onBlur={handleBlur}
            formatter={(value) => replaceNumberToAmount(value)}
            parser={(value) => (value ? value.replace(/\$\s?|(,*)/g, "") : 0)}
            touched={touched.amount}
            error={errors.amount}
            onInput={(e) => {
              const value = e ? e.replace(/\$\s?|(,*)/g, "") : 0;
              handleSetSuggestAmount(suggestAmountForUser(value));
              setFieldValue("amount", value);
            }}
          />
          <div className={styles["wrap-suggest-amout"]}>
            {suggestAmount.map((amount, index) => (
              <div
                key={index}
                onClick={() => {
                  handleSetSuggestAmount(suggestAmountForUser(amount));
                  setFieldValue("amount", amount);
                }}
                className={styles["suggest-amount"]}
              >
                {replaceNumberToAmount(amount) + " VND"}
              </div>
            ))}
          </div>
          <MyInput
            size="large"
            value={values.paymentRef}
            name="paymentRef"
            label={t("PaymentRef")}
            placeholder={t("PaymentRef")}
            onBlur={handleBlur}
            touched={touched.paymentRef}
            error={errors.paymentRef}
            onChange={(e) => {
              setFieldValue("paymentRef", e.target.value);
            }}
          />
          <MyTextInput
            size="large"
            value={values.content}
            label={t("transaction_transfer_content")}
            required
            showCount
            maxLength={MAX_LENGTH_CONTENT}
            name="content"
            placeholder={t("transaction_transfer_content")}
            onBlur={handleBlur}
            touched={touched.content}
            error={errors.content}
            suffix={<AuditOutlined />}
            onChange={(e) => {
              setFieldValue("content", e.target.value);
            }}
            autoSize={{ minRows: 4, maxRows: 5 }}
          />
          <Button loading={loading} type="primary" onClick={() => submitForm()}>
            {t("payment")}
          </Button>
        </Form>
      </div>
    </div>
  );
}
